<template>
  <div
    class="ExtraFilter StatusFilter"
    :class="{
      'ExtraFilter--open': isDropDownOpen,
    }"
  >
    <div class="block mb-[10px] text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white text-left 2xl:text-[14px] 2xl:leading-[16px]">Status</div>
    <div
      class="relative"
      v-click-outside="closeDropDown"
    >
      <div
        :class="styleStatusFilter"
        @click="toggleDropDown"
      >
        <div
          class="grow-[1] text-gray-600 dark:text-white"
          v-if="isStatusAll"
        >
          All
        </div>
        <StatusFilterItem v-else 
          closeButton
          @deleteItem="deleteItem"
          :status="status" 
        />
        <i aria-hidden="true" class="material-icons ExtraFilter_arrow">arrow_drop_down</i>
      </div>
      <div class="ExtraFilter_drowDown bg-white dark:bg-blue-800">
        <div class="ExtraFilter_filterAll">
          <span v-text="`All`" @click="statusClickHandler('')" />
        </div>
        <i aria-hidden="true" class="material-icons ExtraFilter_arrow" @click="closeDropDown">arrow_drop_down</i>
        <div class="StatusFilter_statusList">
          <StatusFilterItem
            @click.native="statusClickHandler(status)" 
            v-for="status in defaulStatusList"
            v-bind:key="status"
            :status="status"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusFilterItem from './StatusFilterItem/StatusFilterItem';

export default {
  data() {
    return {
      status: this.defaulStatus,
      isDropDownOpen: false,
    };
  },
  props: {
    styleStatusFilter: {
      type: String,
      required: false,
    },
    defaulStatus: {
      type: String,
      required: true,
      default: '',
    },
    defaulStatusList: {
      type: Array,
      required: true,
      default: [],
    },
    emitField: {
      type: String,
      required: false,
      default: 'order_status',
    },
  },
  methods: {
    deleteItem({statusClickHandler}=this) {
      statusClickHandler('')
    },
    toggleDropDown() {
      this.isDropDownOpen = !this.isDropDownOpen;
    },
    closeDropDown() {
      this.isDropDownOpen = false;
    },
    statusClickHandler(status) {
      this.setStatus(status);
      this.closeDropDown();
    },
    setStatus(status) {
      this.status = status.toUpperCase();
      this.emitChange();
    },
    emitChange() {
      const value = this.status;
      const field = this.emitField;
      this.$emit('change', { field, value });
    },
  },
  computed: {
    isStatusAll() {
      return this.status == '';
    },
  },
  components: {
    StatusFilterItem,
  },
};
</script>

<style lang="scss">
@import './StatusFilter.scss';
</style>