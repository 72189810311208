<template>
  <section class="rounded-[3px] relative bg-[#F6F6F6]/[.70] dark:shadow-component-wrapper-root dark:bg-blue-800/[0.44]">
    <div class="flex justify-between items-center absolute top-0 left-0 right-0 font-medium text-[18px] leading-[21px] shadow-component-wrapper-heading bg-white text-blue-700 text-left dark:bg-component-wrapper-heading px-[20px] py-[14px]">
      <div class="flex justify-between items-center w-full text-blue-700 dark:text-blue-500 leading-[1]">
        {{ title }}
        <slot name="subTitle" />
        <slot name="tollbar"/>
      </div>
      <slot name="filterWallet" />
    </div>
    <div class="pt-[55px]">
      <slot name="content" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Exchange',
    },
    withSubTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
