// provider transactions history filters start
const PROVIDER_TRANSACTIONS_HISTORY_TYPES_FILTER = [
  {
    key: '',
    value: 'Type',
  },
  {
    key: 'DEPOSIT',
    value: 'Deposit',
  },
  {
    key: 'WITHDRAWAL',
    value: 'Withdrawal',
  },
];

/*const PROVIDER_TRANSACTIONS_HISTORY_STATUSES_FILTER = [
  {
    key: '',
    value: 'Status',
  },
  {
    key: 'SUCCESS',
    value: 'Success',
  },
  {
    key: 'FAILED',
    value: 'Failed',
  },
  {
    key: 'PENDING',
    value: 'Pending',
  },
];*/
const PROVIDER_TRANSACTIONS_HISTORY_STATUSES_FILTER = [
  'success',
  'failed',
  'pending',
  // 'closed',
  // 'waiting',
  // 'new',
  // 'cancelled',
  // 'error',
  // 'expired',
  // 'blocked',
]
// provider transactions history filters end

// orders history filters start
const HISTORY_TYPES_FILTER = [
  {
    key: '',
    value: 'Type',
  },
  {
    key: 'EXCHANGE',
    value: 'Exchange',
  },
  {
    key: 'DEPOSIT',
    value: 'Deposit',
  },
  {
    key: 'WITHDRAWAL',
    value: 'Withdrawal',
  },
  {
    key: 'INTERNAL_MOVEMENT',
    value: 'Internal Transfers',
  },
  {
    key: 'INVOICE',
    value: 'Invoice',
  },
];

/*const HISTORY_STATUSES_FILTER = [
  {
    key: '',
    value: 'Status',
  },
  {
    key: 'NEW',
    value: 'New',
  },
  {
    key: 'ERROR',
    value: 'Error',
  },
  {
    key: 'EXPIRED',
    value: 'Expired',
  },
  {
    key: 'CANCELLED',
    value: 'Cancelled',
  },
  {
    key: 'CLOSED',
    value: 'Closed',
  },
];*/

const HISTORY_STATUSES_FILTER = [
  'closed',
  'waiting',
  'new',
  'cancelled',
  // 'cancelling',
  'error',
  'expired',
  'blocked',
]

// orders history filters end

export {
  PROVIDER_TRANSACTIONS_HISTORY_TYPES_FILTER,
  PROVIDER_TRANSACTIONS_HISTORY_STATUSES_FILTER,
  HISTORY_TYPES_FILTER,
  HISTORY_STATUSES_FILTER,
};
