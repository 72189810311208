<template>
  <div class="DatePicker_root">
    <div class="DatePicker_wrapper">
      <div class="DatePicker_heading">
        <div
          class="DatePicker_switcher"
          v-if="!hideSelect"
        >
          <Select
            class="DatePicker_select"
            :items="selectVariants"
            :value="selectValue"
            :name="selectFieldName"
            :label="selectLabel"
            @change="onFilterChange"
          />
        </div>
      </div>
      <div class="DatePicker_btns">
        <Button
          class="btn btn-small btn-default-small min-w-[84px] mr-[10px]"
          @click="handleApplyFilter"
          text="Apply"
        />
        <Button
          class="btn btn-small btn-default-small min-w-[84px]"
          @click="handleClearFilter"
          text="Clear"
        />
      </div>
      <v-date-picker
        v-model="dates"
        :range="dateRange"
        @change="handleUpdateDateValue"
        color="#2AF3F3"
        header-color="#2D4D70"
      ></v-date-picker>
      <div class="DatePicker_btns">
        <Button
          class="btn btn-small btn-default-small min-w-[84px] mr-[10px]"
          @click="handlePeriodFilter(7)"
          text="Week"
        />
        <Button
          class="btn btn-small btn-default-small min-w-[84px] mr-[10px]"
          @click="handlePeriodFilter(31)"
          text="Month"
        />
        <Button
          class="btn btn-small btn-default-small min-w-[84px]"
          @click="handlePeriodFilter(93)"
          text="3 Months"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/_common/FormElements/Select/Select';
import Button from '@/components/_common/FormElements/Button/Button';

export default {
  components: {
    Button,
    Select,
  },
  props: {
    datesValue: {
      type: [Object, Array],
    },
    selectValue: {
      type: [Object, String],
    },
    selectVariants: {
      type: Array,
    },
    selectFieldName: {
      type: String,
    },
    selectLabel: {
      type: String,
    },
    dateRange: {
      type: Boolean,
      default: false,
    },
    hideSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dates: null,
  }),
  watch: {
    datesValue: {
      handler(val) {
        if (val && val instanceof Object) {
          this.dates = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onFilterChange(payload) {
      this.$emit('updateSelect', payload);
    },
    handleUpdateDateValue(payload) {
      this.dates = payload;
      this.$emit('updateDate', payload);
    },
    handleApplyFilter() {
      this.$emit('submit');
    },
    handleClearFilter() {
      this.$emit('clear');
    },
    handlePeriodFilter(period) {
      let firstDate = new Date();
      if (this.dates.length === 1) {
        firstDate = new Date(this.dates[0]);
      }
      if (this.dates.length === 2) {
        firstDate = new Date(this.dates[1]);
      }
      period--
      let lastDate = new Date(firstDate)
      lastDate.setDate(firstDate.getDate() - period)
      this.dates = [this.dateFormatter(firstDate), this.dateFormatter(lastDate)]
      this.$emit('updateDate', this.dates);
      this.$emit('submit');
    },
    dateFormatter(date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      month = month*1 < 10 ? '0'+month : month
      let day = date.getDate()
      day = day*1 < 10 ? '0'+day : day
      return year+'-'+month+'-'+day
    },
  },
};
</script>

<style lang="scss">
.DatePicker_root {
  color: #fff;
  box-shadow: 0 18px 34px rgba(34, 39, 47, 0.55);
  border-radius: 4px;
  position: absolute;
  top: 100%;

  .DatePicker_wrapper {
    padding: 10px;
    background-color: #2d4d70;
    position: relative;
    z-index: 9;

    .DatePicker_heading {
      background: #2d4d70;
      padding: 10px 0;
    }

    .DatePicker_switcher {
      max-width: 290px;
      margin: 0 auto;
    }

    .v-picker.v-picker--date {
      border: none;

      .v-date-picker-header__value {
        display: flex;
        position: relative;
        white-space: nowrap;
      }

      .tab-reverse-transition-leave-to,
      .tab-transition-leave-to,
      .tab-reverse-transition-enter-to,
      .tab-transition-enter-to {
        position: absolute !important;
        transition: none !important;
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }

      &.theme--dark {
        background: #2d4d70;

        .v-picker__title {
          display: none;
        }

        .v-picker__body {
          background-color: #2d4d70;

          .v-date-picker-table {
            table {
              tbody {
                tr {
                  td {
                    button {
                      &.v-btn--active {
                        border: none;
                        background-color: rgba(#2af3f3, 0.1) !important;
                        color: #2af3f3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.DatePicker_btns {
  button {
    margin: 0 5px;
  }
}
</style>
