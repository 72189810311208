<template>
  <div
    class="CurrencyFilter text-left"
    :class="{
      'ExtraFilter--open': isDropDownOpen,
      'ExtraFilter--inlineRight': inlineRight,
    }"
  >
    <div
      v-if="filterHasLabel"
      class="block mb-[10px] text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white text-left 2xl:text-[14px] 2xl:leading-[16px]"
    >Currency</div>
    <div
      class="relative"
      v-click-outside="closeDropDown"
    >
      <div
        :class="currencyFilterStyles"
        @click="toggleDropDown"
      >
        <div
          class="grow-[1] text-gray-600 dark:text-white"
          v-if="isCurrencyAll"
        >
          All
        </div>
        <CurrencyIcon
          v-else
          :currency="currency"
        />
        <i aria-hidden="true" class="material-icons extra-filter-arrow">arrow_drop_down</i>
      </div>
      <div class="ExtraFilter_drowDown">
        <div
          v-if="filterHasAllValue"
          class="ExtraFilter_filterAll"
        >
          <span
            v-text="`All`"
            @click="currencyClickHandler('')"
          />
        </div>
        <input
          ref="search"
          placeholder="Search"
          type="text"
          class="CurrencyFilter_search"
          v-model="search"
        />
        <i aria-hidden="true" class="material-icons extra-filter-arrow" @click="closeDropDown">arrow_drop_down</i>
        <div class="CurrencyFilter_currencyList">
          <div class="CurrencyFilter_currencyListGroup" 
            v-for="group in currencyList" 
            :key="`${group.title}-${componentID}`"
          >
            <div class="CurrencyFilter_currencyListGroup_title">{{ group.title }}</div>
            <div class="CurrencyFilter_currencyListGroupItems">
              <CurrencyIcon
                @click.native="currencyClickHandler(item.value)"
                v-for="item in group.items"
                :key="`${item.value}-${componentID}`"
                :currency="item.value"
              />
            </div>
          </div>
          <div
            class="CurrencyFilter_currencyList_Empty"
            v-if="currencyList.length == 0"
          >
            Currency not found
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyIcon from '@/components/_common/CurrencyIcon/CurrencyIcon';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

export default {
  name: 'CurrencyFilter',
  components: {
    CurrencyIcon
  },
  data() {
    return {
      currency: this.defaulCurrency,
      search: '',
      isDropDownOpen: false,
      componentID: this._uid,
    };
  },
  props: {
    currencyFilterStyles: {
      type: String,
      required: false,
      default: false,
    },
    inlineRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaulCurrency: {
      type: String,
      required: true,
      default: '',
    },
    defaulCurrencyList: {
      type: Array,
      required: true,
      default: [],
    },
    filterHasLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    emitField: {
      type: String,
      required: false,
      default: 'currency',
    },
    filterHasAllValue: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  methods: {
    toggleDropDown() {
      this.isDropDownOpen = !this.isDropDownOpen
      if (this.isDropDownOpen) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    closeDropDown() {
      this.isDropDownOpen = false
    },
    currencyClickHandler(currency) {
      this.setCurrency(currency)
      this.closeDropDown()
    },
    setCurrency(currency) {
      this.currency = currency.toUpperCase()
      this.emitChange()
    },
    emitChange() {
      const value = this.currency
      const field = this.emitField
      this.$emit('change', { field, value })
    },
  },
  watch: {
    defaulCurrency: function(newVal) {
      this.currency = newVal
    }
  },
  computed: {
    isCurrencyAll() {
      return this.currency == ''
    },
    currencyAllList() {
      let currencyAllList = [ { title: 'Crypto', items:[] }, { title: 'Fiat', items:[] }, ]
      this.defaulCurrencyList.forEach(currency => {
        let cryptoGroup = currencyAllList.find(group=>group.title=='Crypto')
        let fiatGroup = currencyAllList.find(group=>group.title=='Fiat')
        let key = currency.key
        if ( isCrypto(currency.key) ) {
          if (cryptoGroup.items.findIndex(currency=>currency.key == key) == -1) {
            cryptoGroup.items.push(currency)
          }
        } else {
          if (fiatGroup.items.findIndex(currency=>currency.key == key) == -1) {
            fiatGroup.items.push(currency)
          }
        }
      })
      return currencyAllList
    },
    currencyList() {
      let currencyList = []
      this.currencyAllList.forEach(currencyGroup => {
        let items = currencyGroup.items.filter(currency => {
          return currency.value.toUpperCase().indexOf(this.search.toUpperCase()) != -1
        });
        if (items.length > 0) {
          currencyList.push({
            title: currencyGroup.title,
            items,
          });
        }
      });
      return currencyList
    },
  },
};
</script>

<style lang="scss">
  @import './CurrencyIcon.scss';
  @import './CurrencyFilter.scss';
</style>