<template>
  <div
    :class="{
      'StatusFilter_statusListItem': true,
      'StatusFilter_statusListItem--withClose': closeButton,
      [statusClass]: true,
    }"
  >
    <div class="StatusFilter_statusListItem_title">{{ statusFormatter }}</div>
    <i v-if="closeButton" class="StatusFilter_statusListItem_icon material-icons" 
      @click="$emit('deleteItem')"
    >close</i>
  </div>
</template>

<script>
export default {
  computed: {
    statusClass({status}=this) {
      return `StatusFilter_statusListItem--${this.statusFormatter}`
    },
    statusFormatter({status}=this) {
      const statusRename = {
        closed: 'completed',
      }
      status = status.toLowerCase()
      if (statusRename[status]) {
        status = statusRename[status]
      }
      return status 
    },
  },
  props: {
    status: {
      type: String,
      required: true,
      default: '',
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: false,
    },  
  },
  methods: { 
  }
};
</script>

